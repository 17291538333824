const news = {
    state: {
        news: null,
    },

    mutations: {
        newsUpdate: function (state, payload) {
            state.news = payload;
        }
    },

    actions: {
        getNews: function (context) {
            window.api.get('news')
                .then(response => {
                    context.commit('newsUpdate', response.data.data.data || [])
                })
                .catch(() => {
                    context.commit('newsUpdate', [])
                })
        }
    },

    getters: {
        news: function (state) {
            return state.news;
        },
        lastEntry: function (state) {
            return state.news ? state.news[0] : {};
        }
    }
}

export default news;
