<template>
    <span class="spinner-border" :class="`spinner-border-${size}`" role="status"></span>
</template>

<script>
export default {
    name: "LoaderComponent",
    props: {
        size: {
            default: 'sm'
        }
    }
}
</script>

<style scoped>

</style>
