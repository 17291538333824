import Vue from 'vue';
import App from './App.vue';
import api from './helpers/api';
import router from './router';
import store from './store/index';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueCarousel from 'vue-carousel';
import i18n from './i18n';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

window.api = api;
const moment = require('moment');
require('moment/locale/pl')

Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_ATTR: ['target']
  }
});
Vue.use(VueCarousel)
Vue.use(require('vue-moment'), {moment});


Vue.use(VueGtag, {
    config: {
        //id: 'G-ZTR5XRH9HS'//BG Bułgaria
        id: 'G-983HLBNPQ0'//HR Chorwacja
        //id: 'G-11RCM9YRQ7'//SI Słowenia
    }
})


new Vue({
  router,
  store,
    i18n,
  render: h => h(App)
}).$mount('#app')

Vue.filter('number', function (value, decimals = 0) {
  if (isNaN(value)) {
    value = 0;
  }

  value = parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: decimals});
  return value.replaceAll(',', ' ').replaceAll('.', ',')
})

Vue.filter('money', (value) => {
  return Vue.filter('number')(value) + '\xa0' + '€';
})
