import Vue from 'vue'
import Vuex from 'vuex'

import user from "./states/user";
import news from "./states/news";
import popup from './states/popup';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        openMenu: false,
    },
    mutations: {
        toggleMenu: function (state, open) {
            state.openMenu = open;
        }
    },
    modules: {
        user: user,
        news: news,
        popup: popup,
    }
});

export default store;
